<script setup lang="ts">
import type { FormattedBibleVerse } from 'global'
import nuxtStorage from 'nuxt-storage'
import { useBibleStore } from '~/store/bible'
import { useAssistantStore } from '~/store/assistant'
import { BibleVersionIds } from '~/enums'
definePageMeta({
  layout: 'bible',
})

const { t } = useI18n()

const bibleVersionId = computed(() => {
  const version = t('default_bible')
  return BibleVersionIds[version as keyof typeof BibleVersionIds]
})

const bibleStore = useBibleStore()
const assistantStore = useAssistantStore()
const { setLanguage } = assistantStore
const {
  getBible,
  selectVerse,
  deselectVerse,
  setCurrentBibleBook,
  setCurrentBibleChapter,
  setCurrentChapterContent,
} = bibleStore
const {
  bible,
  currentBookName,
  currentChapter,
  currentChapterContent,
  isLoading,
  selectedVerses,
} = storeToRefs(bibleStore)
try {
  await getBible(bibleVersionId.value, t('brand'))
} catch (error) {
  useBugsnag().notify(error, {
    severity: 'error',
    context: 'Failed to initiate Bible page and load the Bible',
  })
}

const formattedVerses = computed(() => {
  return useFormatVerses(currentChapterContent.value)
})

const selectBibleVerse = (verse: FormattedBibleVerse) => {
  if (selectedVerses.value?.find((v) => v.verseNumber === verse.verseNumber)) {
    deselectVerse(verse)
  } else {
    selectVerse(verse)
  }
}

const nuxtApp = useNuxtApp()
const nuxtBus = nuxtApp.$bus as any
nuxtBus.$on('swipe', async (direction: string) => {
  if (isLoading.value) return
  try {
    if (direction === 'right') {
      setCurrentBibleChapter((+currentChapter.value - 1).toString())
      await setCurrentChapterContent(t('brand'))
    }
    if (direction === 'left') {
      setCurrentBibleChapter((+currentChapter.value + 1).toString())
      await setCurrentChapterContent(t('brand'))
    }
  } catch (error) {
    useBugsnag().notify(error, {
      severity: 'error',
      context: 'Failed to swipe to next Bible chapter',
      metaData: {
        direction,
        currentChapter: currentChapter.value,
        bible: bible.value?.name,
      },
    })
  }
})

onNuxtReady(() => {
  try {
    const currentBibleBook =
      nuxtStorage.localStorage.getData('currentBibleBook')
    const currentBibleChapter = nuxtStorage.localStorage.getData(
      'currentBibleChapter',
    )
    if (currentBibleBook && currentBibleChapter) {
      setCurrentBibleBook(currentBibleBook)
      setCurrentBibleChapter(currentBibleChapter)
      setCurrentChapterContent(t('brand'))
    }

    // const assistantType = nuxtStorage.localStorage.getData('assistantType')
    // if (assistantType) {
    //   setAssistantType(assistantType)
    // }
    const language = nuxtStorage.localStorage.getData('language')
    if (language) {
      setLanguage(language)
    }
  } catch (error) {
    useBugsnag().notify(error, {
      severity: 'error',
      context:
        'Failed to set user settings from local storage on Bible page load',
    })
  }
})
</script>
<template>
  <div
    class="text-gray-700 dark:text-gray-100 mt-4 grid grid-cols-2 lg:grid-cols-3 gap-4"
  >
    <div class="col-span-2 w-full sm:overflow-y-auto box-border max-h-[85vh]">
      <div class="max-w-4xl mx-auto">
        <div class="hidden md:flex md:mb-6">
          <h1 class="text-3xl font-bold">
            {{ currentBookName }} {{ currentChapter }}
          </h1>
          <h2 class="text-md font-semibold ml-3">{{ bible?.name }}</h2>
        </div>

        <div v-show="isLoading" class="flex justify-center grow">
          <span class="loading loading-ring loading-lg"></span>
        </div>
        <ClientOnly>
          <div v-show="!isLoading">
            <Swipe>
              <div
                v-for="(verse, index) in formattedVerses"
                :key="index"
                :class="[
                  'flex py-2 text-lg -mx-2 md:mx-0 px-2 cursor-pointer hover:md:bg-sky-50 dark:md:hover:bg-sky-200 dark:md:hover:text-gray-900',
                  selectedVerses?.find(
                    (v) => v.verseNumber === verse.verseNumber,
                  )
                    ? 'bg-sky-50 text-sky-800 font-medium dark:bg-sky-200 dark:text-gray-900'
                    : 'bg-transparent',
                ]"
                @click.stop="selectBibleVerse(verse)"
              >
                <span class="mr-4 font-medium text-gray-300">{{
                  verse.verseNumber
                }}</span>
                <p>
                  {{ verse.text }}
                </p>
              </div>
            </Swipe>
          </div>
        </ClientOnly>
      </div>
    </div>
    <ChatBibleStream class="hidden lg:block max-h-[85vh]" />
  </div>
</template>
<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
