import type { BibleChapterParagraph, FormattedBibleVerse } from 'global'

export const useFormatVerses = (
  currentChapterContent: BibleChapterParagraph[],
) => {
  const verses = [] as FormattedBibleVerse[]
  let currentVerse = ''
  let currentText = ''
  currentChapterContent?.forEach((p) => {
    p.content?.forEach((verse) => {
      if (verse.type === 'verse-number') {
        // If we already have some text, push the previous verse and its text
        if (currentText) {
          verses.push({ verseNumber: currentVerse, text: currentText.trim() })
        }
        // Start a new paragraph
        currentVerse = verse.content
        currentText = ''
      } else if (verse.type === 'verse-text') {
        // Add space before the text if it's not the first text in the paragraph
        if (currentText) currentText += ' '
        currentText += verse.content
      }
    })
  })
  // Push the last verse and its text after the loop
  if (currentText) {
    verses.push({ verseNumber: currentVerse, text: currentText.trim() })
  }

  return verses
}
